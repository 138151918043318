var ua = navigator.userAgent
let isWeChat = ua.toLowerCase().indexOf('micromessenger') !== -1
let token = localStorage.getItem('token')

let code = geturlparam('code')
let appid = 'wxb1fa18e71a2afe8c'

function geturlparam(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r !== null) return unescape(r[2])
  return null
}

let url = 'http://ainlc.beibianjk.com/register'

// 封装授权封装
export function handleAuth(isHref) {
  if(isWeChat && token == null) {
    if (code == null || code === '') {
      if(location.href.indexOf('/share') == -1) {
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          appid +
          '&redirect_uri=' +
          encodeURIComponent(isHref ? url : window.location.href) +
          '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
      }
    }
  }
}


